import { BISAACLResource } from "app/modules/bisa/bisa-constants";
import { EPermissionOption, EUserType } from "app/store/auth/model/enums";
import { NavConfigAcl } from "app/store/auth/model/navConfigAcl";

export interface InfoPostNavConfig {
  id: string;
  label: string;
  icon?: string;
  route?: string;
  featureName?: string;
  href?: string; // Don't use both route and href at the same time
  subMenuItems?: InfoPostNavConfig[];
  refID?: string;
  nextRefID?: string;
  tspApplicable?: Array<number>;
  displayOrder?: number;
  isapplicableDownload?: boolean;
  downloadMenuLabel?: string;
  downloadDisplayOrder?: number;
  acl?: NavConfigAcl;
  skipAclCheck?: boolean;
}

export const enum TSPEnum {
  GULFSOUTH = 1,
  BOARDWALK = 6,
  LOUISIANA = 8,
  TEXAS = 100000,
  OTHER = -1
}

export const applicationMenuConfig: InfoPostNavConfig[] = [
  {
    id: 'informational-main',
    label: 'Main',
    route: '/main',
    icon: 'icon-sitemap',
    nextRefID: 'MAIN',
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    displayOrder: 1,
    isapplicableDownload: false,
    skipAclCheck: true
  },
  {
    id: 'informational-posting-main',
    label: 'Informational Postings',
    route: '/informational-posting',
    icon: 'icon-info',
    refID: 'MAIN',
    nextRefID: 'INFOPOST',
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS, TSPEnum.LOUISIANA],
    displayOrder: 1,
    isapplicableDownload: false,
    skipAclCheck: true
  },
  {
    id: 'customer-activities-main',
    label: 'Customer Activities',
    route: '/home',
    icon: 'icon-customer-activities',
    refID: 'MAIN',
    nextRefID: 'ADMIN',
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS, TSPEnum.LOUISIANA],
    displayOrder: 2,
    isapplicableDownload: false,
    skipAclCheck: true
  },
  {
    id: 'infopost-home',
    label: 'Informational Postings',
    route: '/informational-posting',
    icon: 'icon-info',
    refID: 'INFOPOST',
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS, TSPEnum.LOUISIANA],
    displayOrder: 1,
    isapplicableDownload: false,
    skipAclCheck: true
  },
  {
    id: 'bisa-main',
    label: 'Bisa',
    route: '/bisa/home',
    icon: 'icon-bisa',
    refID: 'MAIN',
    nextRefID: 'BISA',
    tspApplicable: [TSPEnum.OTHER],
    displayOrder: 3,
    skipAclCheck: true
  },
  {
    id: 'capacity',
    label: 'Capacity',
    icon: 'icon-infopost-capacity',
    refID: 'INFOPOST',
    displayOrder: 2,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'operationally-available',
        label: 'Operationally Available',
        route: '/capacity/operationally-available',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadDisplayOrder: 1,
        skipAclCheck: true
      },
      {
        id: 'unsubscribed',
        label: 'Unsubscribed Capacity',
        route: '/capacity/unsubscribed',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadDisplayOrder: 2,
        skipAclCheck: true
      },
      {
        id: 'unsubscribed-FTS-A',
        label: 'Unsubscribed FTS-A',
        route: '/capacity/unsubscribed-FTS-A',
        tspApplicable: [TSPEnum.GULFSOUTH],
        isapplicableDownload: true,
        downloadMenuLabel: 'Unsubscribed Capacity FTS-A',
        downloadDisplayOrder: 3,
        skipAclCheck: true
      },
      {
        id: 'no-notice-activity',
        label: 'No-Notice Activity',
        route: '/capacity/no-notice-activity',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadDisplayOrder: 4,
        skipAclCheck: true
      },
      {
        id: 'scheduling-constrained-areas',
        label: 'Scheduling & Constrained Areas',
        route: '/capacity/scheduling-constrained-areas',
        tspApplicable: [TSPEnum.GULFSOUTH],
        isapplicableDownload: true,
        downloadDisplayOrder: 16,
        skipAclCheck: true
      },
      {
        id: 'scheduling-group-available',
        label: 'Scheduling Group Available',
        route: '/capacity/scheduling-group-available',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK],
        isapplicableDownload: true,
        downloadMenuLabel: 'Scheduling Group Available Capacity',
        downloadDisplayOrder: 17,
        skipAclCheck: true
      },
    ]
  },
  {
    id: 'gas-quality',
    label: 'Gas Quality',
    icon: 'icon-infopost-gas-quality',
    refID: 'INFOPOST',
    displayOrder: 3,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'gas-quality-report',
        label: 'Gas Quality Report',
        route: '/gas-quality/gas-quality-report',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Gas Quality',
        downloadDisplayOrder: 5,
        skipAclCheck: true
      },
      {
        id: 'gas-quality-tariff-provisions',
        label: 'Gas Quality Tariff Provisions',
        route: '/gas-quality/gas-quality-tariff-provisions',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: false,
        skipAclCheck: true
      },
      {
        id: 'gas-quality-procedures',
        label: 'Gas Quality Procedures',
        route: '/gas-quality/gas-quality-procedures',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: false,
        skipAclCheck: true
      },
      {
        id: 'coastal-bend-gas-quality',
        label: 'Coastal Bend Gas Quality',
        featureName: 'AllowCoastalBendHeaderWebApp',
        // route: '/coastal-bend-gas-quality',
        tspApplicable: [TSPEnum.GULFSOUTH],
        isapplicableDownload: false,
        skipAclCheck: true,
        subMenuItems: [
          {
            id: 'receipt-point-gas-composition',
            label: 'Receipt Point Gas Composition',
            route: '/gas-quality/receipt-point-gas-composition',
            tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
            isapplicableDownload: false,
            skipAclCheck: true
          },
          {
            id: 'stratton-ridge-gas-composition',
            label: 'Stratton Ridge Gas Composition',
            route: '/gas-quality/stratton-ridge-gas-composition',
            tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
            isapplicableDownload: false,
            skipAclCheck: true
          },
          {
            id: 'historical-gas-quality',
            label: 'Historical Gas Quality',
            route: '/gas-quality/historical-gas-quality',
            tspApplicable: [TSPEnum.GULFSOUTH],
            isapplicableDownload: false,
          },
          {
            id: 'posted-gas-quality-specifications',
            label: 'Posted Gas Quality Specifications',
            route: '/gas-quality/posted-gas-quality-specifications',
            tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
            isapplicableDownload: false,
            skipAclCheck: true
          },
          {
            id: 'safe-harbor-tariff-provisions',
            label: 'Safe Harbor Tariff Provisions',
            route: '/gas-quality/safe-harbor-tariff-provisions',
            tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
            isapplicableDownload: false,
            skipAclCheck: true
          },
          {
            id: 'communication-protocol',
            label: 'Communication Protocol',
            route: '/gas-quality/communication-protocol',
            tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
            isapplicableDownload: false,
            skipAclCheck: true
          }
        ]
      },

    ]
  },
  {
    id: 'index-of-customers',
    label: 'Index of Customers',
    route: '/informational-posting/index-of-customers',
    icon: 'icon-infopost-index-of-customer',
    refID: 'INFOPOST',
    displayOrder: 4,
    isapplicableDownload: true,
    downloadDisplayOrder: 6,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'locations',
    label: 'Locations',
    route: '/informational-posting/locations',
    icon: 'icon-infopost-location',
    refID: 'INFOPOST',
    displayOrder: 5,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'notices',
    label: 'Notices',
    icon: 'icon-infopost-notices',
    refID: 'INFOPOST',
    displayOrder: 6,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'critical',
        label: 'Critical',
        route: '/notices/critical',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Critical Notices',
        downloadDisplayOrder: 7,
        skipAclCheck: true
      },
      {
        id: 'non-critical',
        label: 'Non-Critical',
        route: '/notices/non-critical',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Non-Critical Notices',
        downloadDisplayOrder: 8,
        skipAclCheck: true
      },
      {
        id: 'planned-service-outage',
        label: 'Planned Service Outage',
        route: '/notices/planned-service-outage',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Planned Service Outages',
        downloadDisplayOrder: 9,
        skipAclCheck: true
      },
      {
        id: 'request-to-purchase-releasable-capacity',
        label: 'Request to Purchase Releasable Capacity',
        route: '/notices/request-to-purchase-releasable-capacity',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: false,
        skipAclCheck: true
      },
      {
        id: 'notices/waste-heat',
        label: 'Waste Heat',
        route: '/notices/waste-heat',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: false,
        skipAclCheck: true
      },
      {
        id: 'notices/capacity-release-indices',
        label: 'Capacity Release Indices',
        route: '/notices/capacity-release-indices',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: false,
        skipAclCheck: true
      },

    ]
  },
  {
    id: 'posted-imbalances',
    label: 'Posted Imbalances',
    route: '/informational-posting/posted-imbalances',
    icon: 'icon-posted-imbalance',
    refID: 'INFOPOST',
    displayOrder: 7,
    downloadDisplayOrder: 10,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    isapplicableDownload: true,
    skipAclCheck: true
  },
  {
    id: 'departmental-contacts',
    label: 'Departmental Contacts',
    icon: 'icon-department-contacts',
    route: '/informational-posting/department-contacts',
    refID: 'INFOPOST',
    displayOrder: 12,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'transactional-reporting',
    label: 'Transactional Reporting',
    icon: 'icon-infopost-transaction-report',
    refID: 'INFOPOST',
    displayOrder: 11,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'capacity-release',
        label: 'Capacity Release',
        route: '/transactional-reporting/capacity-release',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Transactional Reporting - Capacity Release',
        downloadDisplayOrder: 12,
        skipAclCheck: true
      },
      {
        id: 'firm-services',
        label: 'Firm Services',
        route: '/transactional-reporting/firm-services',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Transactional Reporting - Firm Services',
        downloadDisplayOrder: 13,
        skipAclCheck: true
      },
      {
        id: 'interruptible-services',
        label: 'Interruptible Services',
        route: '/transactional-reporting/interruptible-services',
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        isapplicableDownload: true,
        downloadMenuLabel: 'Transactional Reporting - Interruptible Services',
        downloadDisplayOrder: 14,
        skipAclCheck: true
      },
      {
        id: 'manual-releases',
        label: 'Manual Releases',
        route: '/transactional-reporting/manual-releases',
        tspApplicable: [TSPEnum.GULFSOUTH],
        isapplicableDownload: false,
        skipAclCheck: true
      },
    ]
  },
  {
    id: 'forms',
    label: 'Forms',
    icon: 'icon-infopost-form',
    route: '/informational-posting/forms',
    refID: 'INFOPOST',
    displayOrder: 13,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'regulatory',
    label: 'Regulatory',
    icon: 'icon-regulatory-new',
    refID: 'INFOPOST',
    displayOrder: 8,
    isapplicableDownload: false,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'tariff-filings',
        label: 'Tariff Filings',
        route: '/informational-posting/tariff-filings',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
    ]

  },
  {
    id: 'training',
    label: 'Training',
    icon: 'icon-chalkboard-user',
    route: '/informational-posting/training',
    refID: 'INFOPOST',
    displayOrder: 14,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'downloads',
    label: 'Downloads',
    icon: 'icon-infopost-downloads',
    route: '/informational-posting/downloads',
    refID: 'INFOPOST',
    displayOrder: 14,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'rates',
    label: 'Rates',
    icon: 'icon-chalkboard-user',
    route: '/informational-posting/rates',
    refID: 'INFOPOST',
    displayOrder: 15,
    isapplicableDownload: true,
    tspApplicable: [TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'tariff',
    label: 'Tariff',
    icon: 'icon-infopost-tariff',
    refID: 'INFOPOST',
    displayOrder: 10,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'title-sheet-section',
        label: 'Title Sheet/Section',
        route: '/tariff/title-sheet-section',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'table-of-contents',
        label: 'Table of Contents',
        route: '/tariff/table-of-contents',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'preliminary-statement',
        label: 'Preliminary Statement',
        route: '/tariff/preliminary-statement',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'map',
        label: 'Map',
        route: '/tariff/map',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'currently-effective-rates',
        label: 'Currently Effective Rates',
        route: '/tariff/currently-effective-rates',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'rate-schedules',
        label: 'Rate Schedules',
        route: '/tariff/rate-schedules',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'general-terms-and-conditions',
        label: 'General Terms and Conditions',
        route: '/tariff/general-terms-and-conditions',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'form-of-service-agreement',
        label: 'Form of Service Agreement',
        route: '/tariff/form-of-service-agreement',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'entire-tariff',
        label: 'Entire Tariff',
        route: '/tariff/entire-tariff',
        isapplicableDownload: true,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        downloadMenuLabel: 'Tariff',
        downloadDisplayOrder: 11,
        skipAclCheck: true
      },
      {
        id: 'sheet-section-index',
        label: 'Sheet/Section Index',
        route: '/tariff/sheet-section-index',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },

    ]
  },
  {
    id: 'NAESB-code-values',
    label: 'NAESB Code Values',
    icon: 'icon-chalkboard-user',
    refID: 'INFOPOST',
    displayOrder: 17,
    downloadDisplayOrder: 15,
    isapplicableDownload: true,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS]
  },
  {
    id: 'standards-of-conduct',
    label: 'Standards of Conduct',
    icon: 'icon-infopost-standard-of-conduct',
    route: '/informational-posting/standards-of-conduct',
    refID: 'INFOPOST',
    displayOrder: 9,
    isapplicableDownload: false,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    skipAclCheck: true
  },
  {
    id: 'search',
    label: 'Search',
    icon: 'icon-infopost-search',
    refID: 'INFOPOST',
    displayOrder: 19,
    isapplicableDownload: true,
    skipAclCheck: true,
    subMenuItems: [
      {
        id: 'entire-tariff',
        label: 'Entire Tariff (PDF)',
        route: '/search/entire-tariff',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'rate-schedule',
        label: 'Rate Schedule',
        route: '/search/rate-schedule',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'general-terms-and-conditions',
        label: 'General Terms and Conditions',
        route: '/search/general-terms-and-conditions',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
      {
        id: 'form-of-service-agreements',
        label: 'Form(s) of Service Agreements',
        route: '/search/form-of-service-agreements',
        isapplicableDownload: false,
        tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
        skipAclCheck: true
      },
    ]
  },
  // Bisa Nav menu
  {
    id: 'bisa-home',
    label: 'Home',
    route: '/bisa/home',
    icon: 'icon-infopost-gas-quality',
    refID: 'BISA',
    tspApplicable: [TSPEnum.OTHER],
    displayOrder: 1,
    skipAclCheck: true
  },
  {
    id: 'bisa-butanes-specification',
    label: 'BLM Mixed Butanes Specification',
    route: '/bisa/mixed-butane-page',
    icon: 'icon-blm-butane-specification',
    refID: 'BISA',
    tspApplicable: [TSPEnum.OTHER],
    displayOrder: 2,
    acl: {
      resource: BISAACLResource.BLMMixedButane,
      accessLevel: EPermissionOption.ReadOnly
    },
  },
  {
    id: 'bisa-historical-gas-composition',
    label: 'Historical Gas Quality',
    route: '/bisa/gas-compostion-hisorical-page',
    icon: 'icon-h-gas-quality',
    refID: 'BISA',
    tspApplicable: [TSPEnum.OTHER],
    displayOrder: 4,
    acl: {
      accessLevel: EPermissionOption.ReadOnly,
      resource: BISAACLResource.historyPage
    }
  },
  {
    id: 'bisa-historical-truck-summary',
    label: 'Historical Truck Summary',
    route: '/bisa/truck-hisorical-page',
    icon: 'icon-truck-summary',
    refID: 'BISA',
    tspApplicable: [TSPEnum.OTHER],
    displayOrder: 5,
    acl: {
      resource: BISAACLResource.truckHistoryPage,
      accessLevel: EPermissionOption.ReadOnly
    },
  },
]

export const lowerInfoPostNavMenuConfigs: InfoPostNavConfig[] = [
  {
    id: 'customer-activities-main',
    label: 'Customer Activities',
    route: '/home',
    icon: 'icon-customer-activities',
    refID: 'INFOPOST',
    displayOrder: 99,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS, TSPEnum.LOUISIANA],
    isapplicableDownload: false,
    skipAclCheck: true
  },
  {
    id: 'site-map',
    label: 'Site Map',
    route: '/informational-posting/site-map',
    icon: 'icon-sitemap',
    refID: 'INFOPOST',
    displayOrder: 100,
    tspApplicable: [TSPEnum.GULFSOUTH, TSPEnum.BOARDWALK, TSPEnum.TEXAS],
    isapplicableDownload: false,
    skipAclCheck: true
  },
];

export interface FeatureFlag {
  name: string;
  isEnabled: boolean
}
