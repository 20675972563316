import { FeatureFlag, InfoPostDetails, InfoPostDetailsLite, InfoPostNoticeDetails, InfoPostNoticeRequest, InfoPostNoticeTypes, InfoPostRequest, NoticeCapacityReleaseIndex, NoticeCapacityReleaseIndexDetails, NoticeFilterRequest, StaticInformationDetails } from '@gms/reporting-api';
import { createAction, props } from '@ngrx/store';
import { ServiceProvider } from '@gms/tsp-api';
import { BlendingModel, CBHHistoricalPresignedURL, RealTimeRollingAvg } from '@gms/measurement-api';
import { HttpResponse } from '@angular/common/http';

export const fetchNoticeInfo = createAction('[Infopost Notice] Fetch Notice Info', props<{
  note: NoticeFilterRequest
}>());

export const fetchNoticeInfoSuccess = createAction('[Infopost Notice] Fetch Notice Info Success', props<{
  notices: InfoPostNoticeDetails
}>());

export const fetchNoticeInfoError = createAction('[Infopost Notice] Fetch Notice Info Error', props<{
  error: Error
}>());

export const fetchNoticeTypeInfo = createAction('[Infopost Notice Types] Fetch Notice Type Info');

export const fetchNoticeTypeInfoSuccess = createAction('[Infopost Notice Types] Fetch Notice Type Info Success', props<{
  noticeTypes: Array<InfoPostNoticeTypes>
}>());

export const fetchNoticeTypeInfoError = createAction('[Infopost Notice Types] Fetch Notice Type Info Error', props<{
  error: Error
}>());

export const fetchPostingsList = createAction('[Infopost Postings] Fetch Postings List', props<{ posting: InfoPostRequest }>());

export const fetchPostingsListSuccess = createAction('[Infopost Postings] Fetch Postings List Success', props<{ postings: InfoPostDetailsLite }>());

export const fetchPostingsListError = createAction('[Infopost Postings] Fetch Postings List Error', props<{ error: Error }>());

export const fetchNaesbList = createAction('[Infopost NaesbList] Fetch Naesb List', props<{ naesb: InfoPostRequest }>());

export const fetchNaesbListSuccess = createAction('[Infopost NaesbList] Fetch Naesb List Success', props<{ naesb: InfoPostDetailsLite }>());

export const fetchNaesbListError = createAction('[Infopost NaesbList] Fetch Naesb List Error', props<{ error: Error }>());

export const resetPostingsList = createAction('[Infopost Postings] Reset Postings List');

export const resetNoticeData = createAction('[Infopost Notice] Reset Notice Data');

export const fetchInfoPostTspInfo = createAction('[Infopost TSP] Fetch TSP Info');

export const fetchInfoPostTspInfoSuccess = createAction('[Infopost TSP] Fetch TSP Info Success', props<{ tspServiceRequester: Array<ServiceProvider> }>());

export const fetchInfoPostTspInfoError = createAction('[Infopost TSP] Fetch TSP Info Error', props<{ error: Error }>());

export const setSelectedInfoPostTSP = createAction('[Infopost TSP] Place TSP Info', props<{ tspServiceRequester: ServiceProvider, overrideIfAlreadySet?: boolean }>());

export const fetchSelectedInfoPostTSP = createAction('[Infopost TSP] Fetch Selected TSP Info');

export const fetchMenuItemComments = createAction('[Infopost Comments] Fetch Infopost Comments', props<{ tspID: number, infoPostID: number }>());

export const fetchMenuItemCommentsSuccess = createAction('[Infopost Notice] Fetch Infopost Comments Success', props<{
  menuitemsComments: StaticInformationDetails
}>());

export const fetchMenuItemCommentsError = createAction('[Infopost Notice]Fetch Infopost Comments Error', props<{
  error: Error
}>());

export const setSelectedTSPID = createAction('[TSP] Set Selected TSP ID', props<{ selectedTspID?: number }>());

export const fetchNoticeCapacityReleaseIndicesInfo = createAction('[Infopost NoticeCapacityReleaseIndices] Fetch NoticeCapacityReleaseIndices Info', props<{ tspID: number, pageSize: number, pageNumber: number }>());

export const fetchNoticeCapacityReleaseIndicesInfoSuccess = createAction('[Infopost NoticeCapacityReleaseIndices] Fetch NoticeCapacityReleaseIndices Info Success', props<{ capacityRelease: NoticeCapacityReleaseIndexDetails }>());

export const fetchNoticeCapacityReleaseIndicesInfoError = createAction('[Infopost NoticeCapacityReleaseIndices] Fetch NoticeCapacityReleaseIndices Info Error', props<{ error: Error }>());

export const fetchAllPostingsList = createAction('[Infopost Postings] Fetch Postings List', props<{ id: number, posting: InfoPostRequest }>());

export const fetchGroupByPostingsList = createAction(
  '[Infopost GroupByPosting] Fetch Postings List by TspId',
  props<{ groupByPosting: InfoPostRequest }>()
);

export const fetchGroupByPostingsListSuccess = createAction(
  '[Infopost GroupByPosting] Fetch Postings List by TspId Success',
  props<{ tspId: number; groupByPostingsData: InfoPostDetails }>()
);

export const fetchGroupByPostingsListError = createAction(
  '[Infopost GroupByPosting] Fetch Postings List by TspId Error',
  props<{ error: Error }>()
);

export const fetchAllNotices = createAction(
  '[Infopost AllNotices] Fetch AllNotices',
  props<{ note: NoticeFilterRequest }>()
);

export const fetchAllNoticesSuccess = createAction(
  '[Infopost AllNotices] Fetch AllNotices Success',
  props<{ tspId: number; allNotices: InfoPostNoticeDetails }>()
);

export const fetchAllNoticesError = createAction(
  '[Infopost AllNotices] Fetch AllNotices Error',
  props<{ error: Error }>()
);

export const downloadNoticeDocumentAction = createAction(
  '[DOCUMENT] Download Notice Document',
  props<{ noticeID: number }>()
);

export const downloadNoticeDocumentActionSuccess = createAction(
  '[DOCUMENT] Download Notice Document Success'
);

export const downloadNoticeDocumentActionError = createAction(
  '[DOCUMENT] Download Notice Document Error',
  props<{ error: Error }>()
);

export const fetchReceiptGasComposition = createAction(`[CBH] Fetch Receipt Point Gas Composition Data`);

export const fetchReceiptGasCompositionSuccess = createAction(`[CBH] Fetch Receipt Point Gas Composition Data Success`,
  props<{ data: RealTimeRollingAvg }>()
);

export const fetchReceiptGasCompositionError = createAction(`[CBH] Fetch Receipt Point Gas Composition Data Error`, props<{ error: Error }>());

export const fetchRollingAvgGasComposition = createAction(`[RGC] Fetch Rolling Avg Gas Composition Data`);

export const fetchRollingAvgGasCompositionSuccess = createAction(`[RGC] Fetch Rolling Avg Gas Composition Data Success`,
  props<{ data: RealTimeRollingAvg }>()
);

export const fetchRollingAvgGasCompositionError = createAction(`[RGC] Fetch Rolling Avg Gas Composition Data Error`, props<{ error: Error }>());

export const fetchStrattonRidgeGasComposition = createAction(`[SRD] Fetch Stratton Ridge Gas Composition Data`);

export const fetchStrattonRidgeGasCompositionSuccess = createAction(`[SRD] Fetch Stratton Ridge Gas Composition Data Success`,
  props<{ data: BlendingModel }>()
);

export const fetchStrattonRidgeGasCompositionError = createAction(`[SRD] Fetch Stratton Ridge Gas Composition Data Error`, props<{ error: Error }>());

export const fetchHistoricalGasQuality = createAction(`[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality`, props<{fromDate:Date, toDate:Date}>());

export const fetchHistoricalGasQualitySuccess = createAction(`[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality Success`,
  props<{ data: CBHHistoricalPresignedURL; }>()
);

export const fetchHistoricalGasQualityError = createAction(`[HISTORICAL_GAS_QUALITY] Fetch Historical Gas Quality Error`, props<{ error: Error }>());

export const fetchInternalHistoricalGasQuality = createAction(`[INTERNAL_HISTORICAL_GAS_QUALITY] Fetch Internal Historical Gas Quality`, props<{fromDate:Date, toDate:Date}>());

export const fetchInternalHistoricalGasQualitySuccess = createAction(`[INTERNAL_HISTORICAL_GAS_QUALITY] Fetch Internal Historical Gas Quality Success`,
  props<{ data: CBHHistoricalPresignedURL; }>()
);

export const fetchInternalHistoricalGasQualityError = createAction(`[INTERNAL_HISTORICAL_GAS_QUALITY] Fetch Internal Historical Gas Quality Error`, props<{ error: Error }>());

