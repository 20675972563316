import { createErrorState, createLoadingState, createSuccessState } from 'app/store/app/app.models';
import { initialNoticeState, NoticeState } from 'app/store/notices/notice.state';
import { ENoticeActions, NoticeActions } from 'app/store/notices/notice.actions';

export function NoticeReducer(
  state = initialNoticeState,
  action: NoticeActions
): NoticeState {
  switch (action.type) {
    case ENoticeActions.FETCH_NOTICES_LIST:
      return {
        ...state,
        notices: createLoadingState(state.notices),
      };
    case ENoticeActions.FETCH_NOTICES_LIST_SUCCESS:
      return {
        ...state,
        notices: createSuccessState(action.payload),
      };
    case ENoticeActions.FETCH_NOTICES_LIST_ERROR:
      return {
        ...state,
        notices: createErrorState(action.payload.error, {}),
      };
    case ENoticeActions.FETCH_NOTICES_FILTER_LIST:
      return {
        ...state,
        filterData: state.filterData,
      };
    case ENoticeActions.NOTICES_FILTER_LIST:
      return {
        ...state,
        ...state.filterData,
        filterData: {
          filterData: action.payload
        },
      };
      case ENoticeActions.FETCH_NOTICE_LOOKUP:
      return {
        ...state,
        lookupData: createLoadingState(state.lookupData),
      };
    case ENoticeActions.FETCH_NOTICE_LOOKUP_SUCCESS:
      return {
        ...state,
        lookupData: createSuccessState(action.payload),
      };
    case ENoticeActions.FETCH_NOTICE_LOOKUP_FAILURE:
      return {
        ...state,
        lookupData: createErrorState(action.payload.error, {}),
      };

    case ENoticeActions.UPDATE_NOTICES_FILTER_FLAG:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          istriggerSearchApi: action.istriggerSearchApi,
        }
      };
      case ENoticeActions.DELETE_NOTICE:
      return {
        ...state,
        deleteNotice: createLoadingState(state.deleteNotice),
      };
    case ENoticeActions.DELETE_NOTICE_SUCCESS:
      return {
        ...state,
        deleteNotice: createSuccessState(action.payload),
      };
    case ENoticeActions.DELETE_NOTICE_FAILURE:
      return {
        ...state,
        deleteNotice: createErrorState(action.payload.error, false),
      };
      case ENoticeActions.CREATE_NEW_NOTICE:
        return {
          ...state,
          createdNewRecord: createLoadingState(state.createdNewRecord)
        };
      case ENoticeActions.CREATE_NEW_NOTICE_SUCCESS:
        return {
          ...state,
          createdNewRecord: createSuccessState(action.payload)
        };
      case ENoticeActions.CREATE_NEW_NOTICE_FAILURE:
        return {
          ...state,
          createdNewRecord: createErrorState(action.payload.error, {})
        };
      case ENoticeActions.FETCH_NOTICE_BY_ID:
      return {
        ...state,
        noticeDetails: createLoadingState(state.noticeDetails),
      };
      case ENoticeActions.FETCH_NOTICE_BY_ID_SUCCESS:
        return {
        ...state,
        noticeDetails: createSuccessState(action.payload),
      };
      case ENoticeActions.FETCH_NOTICE_BY_ID_FAILURE:
        return {
        ...state,
        noticeDetails: createErrorState(action.payload.error, {}),
      };
     case ENoticeActions.RESET_NOTICE_BY_ID_SUCCESS:
        return {
          ...state,
          noticeDetails: createSuccessState(null)
        };
    default:
      return state;
  }
}
