import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { DeleteHistoricalGCTS, DeleteHistoricalGCTSFailure, DeleteHistoricalGCTSSuccess, EBisaActions, FetchBISAMixedButaneSpecificationsData, FetchBISAMixedButaneSpecificationsError, FetchBISAMixedButaneSpecificationsSuccess, FetchBISAStrattonRidgeData, FetchBISAStrattonRidgeError, FetchBISAStrattonRidgeSuccess, FetchHistoricalGasComposition, FetchHistoricalGasCompositionError, FetchHistoricalGasCompositionSuccess, FetchHistoricalTruckSummary, FetchHistoricalTruckSummaryError, FetchHistoricalTruckSummarySuccess, FetchPostingsButaneInjectionDR, FetchPostingsButaneInjectionDRError, FetchPostingsButaneInjectionDRSuccess, FetchPostingsButaneInjectionMR, FetchPostingsButaneInjectionMRError, FetchPostingsButaneInjectionMRSuccess, FetchPostingsTruckUnloadingSummary, FetchPostingsTruckUnloadingSummaryError, FetchPostingsTruckUnloadingSummarySuccess, UploadXMLFile, IXMLUploadPayload, UploadXMLFileSuccess, UploadXMLFileFailure } from "./bisa.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { BisaService, MixedButaneSpecifications, StrattonRidgeHeaderGasComposition, BISAHistoricalPresignedURL } from "@gms/measurement-api";
import { HttpResponse } from "@angular/common/http";
@Injectable()
export class BISAEffects {
    constructor(
        private _actions$: Actions,
        private service: BisaService
    ) { }

    FetchMixedButaneSpecificationData$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<FetchBISAMixedButaneSpecificationsData>(EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA),
            switchMap(() => {
                return this.service.getBisaMixedButane().pipe(
                    map((result: MixedButaneSpecifications) => {
                        const data: MixedButaneSpecifications = result;
                        return new FetchBISAMixedButaneSpecificationsSuccess({ data })
                    }),
                    catchError(error => of(new FetchBISAMixedButaneSpecificationsError({ error: error })))
                )
            })
        )
    );

    FetchStrattonRidgeData$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<FetchBISAStrattonRidgeData>(EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA),
            switchMap(() => {
                return this.service.getBisaStrattonRidge().pipe(
                    map((result) => {
                        const data: StrattonRidgeHeaderGasComposition = result;
                        return new FetchBISAStrattonRidgeSuccess({ data })
                    }),
                    catchError(error => of(new FetchBISAStrattonRidgeError({ error: error })))
                )
            })
        )
    );

    FetchHistoricalGasComposition$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<FetchHistoricalGasComposition>(EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION),
            map((action: FetchHistoricalGasComposition) => action.payload),
            switchMap(payload => {
                return this.service.getBisaExportReport(payload.fromDate, payload.toDate, payload.reportType).pipe(
                    map((result: BISAHistoricalPresignedURL) => {
                        return new FetchHistoricalGasCompositionSuccess({ data: result })
                    }),
                    catchError(error => of(new FetchHistoricalGasCompositionError({ error: error })))
                )
            })
        )
    );

    FetchHistoricalTruckSummary$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<FetchHistoricalTruckSummary>(EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY),
            map((action: FetchHistoricalTruckSummary) => action.payload),
            switchMap(payload => {
                return this.service.getBisaExportReport(payload.fromDate, payload.toDate, payload.reportType).pipe(
                    map((result: BISAHistoricalPresignedURL) => {
                        return new FetchHistoricalTruckSummarySuccess({ data: result })
                    }),

                    catchError(error => of(new FetchHistoricalTruckSummaryError({ error: error })))
                )
            })
        )
    );

    DeleteHistoricalGCTS$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<DeleteHistoricalGCTS>(EBisaActions.DELETE_HISTORICAL_GC_TS),
            map((action) => action),
            switchMap(payload => {
                return this.service.deleteBisaExportReport(payload.file_name, payload.reportType, "response").pipe(
                    map((result: HttpResponse<object>) => {
                        const data = result;
                        return new DeleteHistoricalGCTSSuccess(data)
                    }),
                    catchError(error => of(new DeleteHistoricalGCTSFailure({ error: error })))
                )
            })
        )
    );

    UploadXMLFile: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType<UploadXMLFile>(EBisaActions.UPLOAD_XML_FILE),
            map((action: UploadXMLFile) => action.payload),
            switchMap((payload: IXMLUploadPayload) => {
                return this.service.postButaneTruckSummary(payload.file)
                    .pipe(
                        map(response => {
                            return new UploadXMLFileSuccess({
                                data: response
                            });
                        }),
                        catchError(error => {
                            return of(new UploadXMLFileFailure(error));
                        })
                    );
            })
        )
    );

}