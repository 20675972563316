import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AllNoticesData, GroupByPostingData, IInfoPostState } from './infopost.state';
import { InfoPostFeatureKey } from './infopost.reducers';
import { TspConfigLite } from '@gms/tsp-api';
import { TSPConstant } from 'shared/utils/tsp.utils';
import { InfoPostDetails, InfoPostNoticeDetails } from '@gms/reporting-api';
import { InformationalWebLabels } from 'app/modules/infopost/informational-posting-web.utils';

export const selectInfoPostState = createFeatureSelector<IInfoPostState>(InfoPostFeatureKey);

export const selectAllNotices = createSelector(selectInfoPostState, (state) => state.notices?.data);

export const selectNoticeLoading = createSelector(
  selectInfoPostState,
  (state) => state?.notices?.requestStatus?.loading
);

export const selectAllNoticeTypes = createSelector(
  selectInfoPostState,
  (state) => state.noticeTypes?.data
);

export const selectNoticeTypeLoading = createSelector(
  selectInfoPostState,
  (state) => state?.noticeTypes?.requestStatus?.loading
);

export const selectAllPostingsList = createSelector(
  selectInfoPostState,
  (state) => state.postings?.data
);

export const selectPostingsListLoading = createSelector(
  selectInfoPostState,
  (state) => state.postings?.requestStatus?.loading
);

export const selectAllInfoPostTSP = createSelector(
  selectInfoPostState,
  (state) => state.tspServiceRequester?.data
);

export const selectAllInfoPostTSPLoading = createSelector(
  selectInfoPostState,
  (state) => state.tspServiceRequester?.requestStatus?.loading
);

export const getSelectedInfoPostTSP = createSelector(
  selectInfoPostState,
  (state) => state.headerInfoPostTSP
);

export const getSelectedMenuItemLoading = createSelector(
  selectInfoPostState,
  (state) => state.menuitemsComments?.requestStatus?.loading
);

export const getSelectedMenuItemComments = createSelector(
  selectInfoPostState,
  (state) => state.menuitemsComments?.data?.messages
);

export const selectnaesbListLoading = createSelector(
  selectInfoPostState,
  (state) => state?.naesbList?.requestStatus?.loading
);

export const selectAllnaesbList = createSelector(
  selectInfoPostState,
  (state) => state.naesbList?.data
);

export const getSelectedTSPID = createSelector(
  selectInfoPostState,
  (state) => state?.selectedTspID
);

export const getNoticeCapacityReleaseInfoLoading = createSelector(
  selectInfoPostState,
  (state) => state.noticeCapacityRelease?.requestStatus?.loading
);

export const getNoticeCapacityReleaseInfo = createSelector(
  selectInfoPostState,
  (state) => state.noticeCapacityRelease?.data
);

export const getValidTSPs = createSelector(selectInfoPostState, (state) => {
  return state?.tspServiceRequester?.data?.filter((x) => getAvailableInfoPostTsp(x.tspConfigs));
});

function getAvailableInfoPostTsp(data: Array<TspConfigLite>) {
  return (
    data.filter(
      (x) => x.configName === TSPConstant.infoPostReportGeneration && x.configValue === 'true'
    ).length > 0
  );
}

export const getPostingsByTspId = (tspId: number) =>
  createSelector(selectInfoPostState, (state: IInfoPostState) => {
    const groupData: GroupByPostingData[] = state.groupByPostingData?.data || [];
    let postingData: InfoPostDetails;
    if (Array.isArray(groupData)) {
      postingData = groupData.find((posting) => posting.tspId === tspId)?.data;
    }
    return postingData;
  });

export const getNoticesByTspId = (tspId: number) =>
  createSelector(selectInfoPostState, (state: IInfoPostState) => {
    const groupData: AllNoticesData[] = state.allNoticesData?.data || [];
    let noticeData: InfoPostNoticeDetails;
    if (Array.isArray(groupData)) {
      noticeData = groupData.find((notice) => notice.tspId === tspId)?.data;
    }
    return noticeData;
  });

export const selectExcludeInfoPostTsp = createSelector(selectInfoPostState, (state) => {
  return state?.tspServiceRequester?.data?.filter((x) => availableInfoPostTsp(x.tspConfigs));
});

const availableInfoPostTsp = (data: Array<TspConfigLite>) => {
  return (
    data.filter(
      (x) => x.configName === TSPConstant.infoPostReportGeneration && x.configValue === 'false'
    ).length > 0
  );
};

export const getFeatureFlageInfo = createSelector(selectInfoPostState, (state) => {
  const data = state.tspServiceRequester?.data || [];
  const configNames = new Set();
  data.forEach((provider) => {
    provider.tspConfigs.forEach((config) => {
      configNames.add(config.configName);
    });
  });
  const result = [];
  configNames.forEach((name) => {
    const isEnabled = data.some((provider) =>
      provider.tspConfigs.some(
        (config) => config.configName === name && config.configValue === 'true'
      )
    );
    result.push({
      name,
      isEnabled,
    });
  });
  return result;
});

export const selectReceiptPointData = createSelector(
  selectInfoPostState,
  (state) => state.receiptPointData
);

export const receiptPointDataLoading = createSelector(
  selectInfoPostState,
  (state) => state.receiptPointData.requestStatus?.loading
);

export const selectRollingAvgData = createSelector(
  selectInfoPostState,
  (state) => state.rollingAvgData
);

export const selectRollingAvgDataLoading = createSelector(
  selectInfoPostState,
  (state) => state.rollingAvgData.requestStatus?.loading
);

export const selectStrattonRidgePointData = createSelector(
  selectInfoPostState,
  (state) => state.strattonData
);

export const strattonRidgePointDataLoading = createSelector(
  selectInfoPostState,
  (state) => state.strattonData.requestStatus?.loading
);


export const selectHistoricalGasQualitySuccess = createSelector(selectInfoPostState, (state) => state.historicalGasQuality);

export const selectHistoricalGasLoading = createSelector(selectInfoPostState, (state) => state.historicalGasQuality.requestStatus?.loading);

export const selectInternalHistoricalGasQualitySuccess = createSelector(selectInfoPostState, (state) => state.historicalGasQuality);

export const selectInternalHistoricalGasLoading = createSelector(selectInfoPostState, (state) => state.historicalGasQuality.requestStatus?.loading);

export const excludeInfoPostMap = createSelector(
  selectInfoPostState,
  (state) => {
      return (state?.tspServiceRequester?.data?.filter(x => availableInfoPostMap(x.tspConfigs)));
  });

const availableInfoPostMap = (data: Array<TspConfigLite>) =>{
  return data.filter(x => x.configName === TSPConstant.EXCLUDE_INFOPOST_MAP && x.configValue === "false").length > 0;
}
