import { MixedButaneSpecifications, StrattonRidgeHeaderGasComposition, BISAHistoricalPresignedURL } from "@gms/measurement-api";
import { emptyRequestStatus, IDataState } from "../app/app.models";

export interface IBisaState {
    bisaMixedButaneData: IDataState<MixedButaneSpecifications>;
    strattonRidgeData: IDataState<StrattonRidgeHeaderGasComposition>;
    bisaHistoricalGasComposition: IDataState<BISAHistoricalPresignedURL>;
    bisaHistoricalTruckSummary: IDataState<BISAHistoricalPresignedURL>;
    bisaPostingTruckUnloadingSummary: IDataState<any>;
    bisaPostingButaneInjectionDR: IDataState<any>;
    bisaPostingButaneInjectionMR: IDataState<any>;
    bisaDeleteHistoricalGCTS: IDataState<any>;
    bisaUploadXMLFile: IDataState<any>;
}

export const initialBisaState: IBisaState = {
    bisaMixedButaneData: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    strattonRidgeData: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaHistoricalGasComposition: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaHistoricalTruckSummary: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaPostingTruckUnloadingSummary: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaPostingButaneInjectionDR: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaPostingButaneInjectionMR: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaDeleteHistoricalGCTS: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
    bisaUploadXMLFile: {
        data: null,
        requestStatus: { ...emptyRequestStatus },
    },
}