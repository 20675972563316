import { ServiceProvider } from '@gms/tsp-api';
import {
  BLGT_TRANSMISSION_CODE,
  BOARDWALK_STORAGE_TRANSMISSION_CODE,
  GULF_SOUTH_TRANSMISSION_CODE,
  TEXAS_GAS_TRANSMISSION_CODE,
  tspShortNameMap,
} from 'shared/consts/tsp.const';

/**
 * getTspIdToTspMap
 * Creates a map of a Tsp's Id as the key and the Tsp as the value
 * @param tsps: Tsp[]
 */
export const getTspIdToTspMap = (tsps: ServiceProvider[]): { [id: number]: ServiceProvider } => {
  return tsps.reduce<{}>((acc, tsp) => {
    acc[tsp.providerId] = tsp;
    return acc;
  }, {});
};

/**
 * getTspNameToTspMap
 * Creates a map of a Tsp's Name as the key and the Tsp as the value
 * @param tsps: Tsp[]
 */
export const getTspNameToTspMap = (
  tsps: ServiceProvider[]
): { [name: string]: ServiceProvider } => {
  return tsps.reduce<{}>((acc, tsp) => {
    acc[tsp.shortName] = tsp;
    return acc;
  }, {});
};

export interface TSP {
  id: number;
  name: string;
}

export interface ITSP<T> extends TSP {
  id: number;
  name: string;
  data: T;
}

export class Tsps<T> {
  constructor() {
    this._map = new Map();

    this._map.set(Tsps._texasGas.id, {
      ...Tsps._texasGas,
      data: {},
    } as ITSP<T>);
    this._map.set(Tsps._gulfSouth.id, {
      ...Tsps._gulfSouth,
      data: {},
    } as ITSP<T>);
    this._map.set(Tsps._boardwalkStorage.id, {
      ...Tsps._boardwalkStorage,
      data: {},
    } as ITSP<T>);
    this._map.set(Tsps._blgt.id, {
      ...Tsps._blgt,
      data: {},
    } as ITSP<T>);
  }

  public static get TEXAS_GAS(): TSP {
    return { ...this._texasGas };
  }

  public static get GULF_SOUTH(): TSP {
    return { ...this._gulfSouth };
  }

  public static get BOARDWALK_STORAGE(): TSP {
    return { ...this._boardwalkStorage };
  }

  public get TEXAS_GAS(): ITSP<T> {
    return this.get(Tsps._texasGas.id);
  }

  public get GULF_SOUTH(): ITSP<T> {
    return this.get(Tsps._gulfSouth.id);
  }

  public get BOARDWALK_STORAGE(): ITSP<T> {
    return this.get(Tsps._boardwalkStorage.id);
  }

  /**
   * return TspDataLookup Dictionary
   */
  public get Map(): { [key: number]: T } {
    return Array.from(this._map.keys()).reduce<{}>((acc: { [key: number]: T }, tspId: number) => {
      const tsp: ITSP<T> = this.get(tspId);
      acc[tspId] = tsp == null ? null : tsp.data;
      return acc;
    }, {});
  }

  private static _texasGas: TSP = {
    id: TEXAS_GAS_TRANSMISSION_CODE,
    name: tspShortNameMap.TEXAS_GAS,
  };

  private static _gulfSouth: TSP = {
    id: GULF_SOUTH_TRANSMISSION_CODE,
    name: tspShortNameMap.GULF_SOUTH,
  };

  private static _boardwalkStorage: TSP = {
    id: BOARDWALK_STORAGE_TRANSMISSION_CODE,
    name: tspShortNameMap.BOARDWALK_STORAGE,
  };

  private static _blgt: TSP = {
    id: BLGT_TRANSMISSION_CODE,
    name: tspShortNameMap.BLGT,
  };

  private _map: Map<number, ITSP<T>>;

  public setTexasGas(data: T) {
    this._map.get(Tsps._texasGas.id).data = data;
    return this;
  }

  public setGulfSouth(data: T) {
    this._map.get(Tsps._gulfSouth.id).data = data;
    return this;
  }

  public setBoardwalkStorage(data: T) {
    this._map.get(Tsps._boardwalkStorage.id).data = data;
    return this;
  }

  public setBLGT(data: T) {
    this._map.get(Tsps._blgt.id).data = data;
    return this;
  }

  /**
   * get TSP object by Id
   */
  public get(tspId: number): ITSP<T> {
    if (!this._map.has(tspId)) return null;
    return {
      //id/name should not be mutable
      id: this._map.get(tspId).id,
      name: this._map.get(tspId).name,
      // data properties not mutable
      data:
        typeof this._map.get(tspId).data === 'undefined' || this._map.get(tspId).data === null
          ? null
          : { ...this._map.get(tspId).data },
    };
  }
}

export enum TSPConstant {
  infoPostReportGeneration = "InfoPostReportGeneration",
  EXCLUDE_INFOPOST_MAP = 'InfoPostMapWebApp',
  SuppressBLMTSP = "SuppressInTspDropDown"
}
