export const BISACONSTANTS = {
    blmButaneHeader: "BLM Mixed Butanes Specification",
    strattonRidgeHeader: "Stratton Ridge Header Gas Composition",
    mixedButaneRoute: "mixedButane",
    tableId: "bisa-table",
    butaneSpecificationsText: "* Could be up to 3.0% due to isobutane readings, whichever specification is least restrictive",
    strattonfooterTextOne: "A Current Flow Rate of 0 indicates composition data will not be provided for the corresponding location.",
    strattonFooterTextTwo: "Freeport data is a calculation only, not real-time chromatograph data.",
    errorEndDateGreater: "End Date must be greater than or equal to the Begin Date.",
    errorBeginAndEndLess: "Begin and End date days must be lesser than to 100 day's",
    historicalGasCompositionRoute: "historicalGasComposition",
    historicalGasQuality: "Historical Gas Quality",
    historicalTruckSummary: "Historical Truck Summary",
    truckUnloadingSummary: "Truck Unloading Summary",
    butaneInjectionDR: "Butane Injection Daily Report",
    butaneInjectionMR: "Butane Injection Monthly Report",
    bisaPosingsTitle: "Postings",
    autoRefreshDefault: "Y",
    GasQualityInterconnectingPipelinesHeader: 'Interconnecting Pipelines',
    tableRow: "gms-table kendo-grid tbody tr",
    tableData: "td",
    styleBackground: "background",
    alertOneColorCode: "#ffff00",
    xmluploadlabel: "Upload EDI Files",
    butanetrucksummaryuploadlabel: "Butane Truck Summary",
    historicalSummaryReportType: "TRUS",
    GasQualityReportType: "HIGQ",
    homeBLMFileName: "BLM Internet User ID-Password Request Form 2.docx",
    BISAppAcess: 'BISAApplicationAccess',
    bisaLogin: '/bisa/login',
    ERROR: " ,Error",
    MSG_ERROR: "error",
}

export interface IHistoricalGasCompositionForm {
    beginDate: Date,
    endDate: Date,
    authUser: boolean
}

export class BISAACLResource {
    static readonly BLMMixedButane = "/bisa/mixed-butane-page";
    static readonly gasCompositionPage = '/bisa/gas-composition-page';
    static readonly historyPage = '/bisa/gas-compostion-hisorical-page';
    static readonly truckHistoryPage = '/bisa/truck-hisorical-page';

    static readonly truckUnloadingSummary = "/bisa/posting/truck-unloading-summary";
    static readonly butaneDailyInjectionReport = "/bisa/posting/butane-injection-daily-report";
    static readonly butaneMonthInjectionReport = "/bisa/posting/butane-injection-monthly-report";
    static readonly posting = '/bisa/manual-posting';
    static readonly truckUploadingSummary = '/bisa/butane-truck-summary-upload';
}