import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountLocked_Routes } from 'app/modules/account-locked/account-locked-routing.module';
import { Home_Routes } from 'app/modules/home/home-routing.module';
import { Login_Routes } from 'app/modules/login/login-routing.module';
import { PasswordReset_Routes } from 'app/modules/password-reset/password-reset-routing.module';
import { PasswordUpdate_Routes } from 'app/modules/password-update/password-update-routing.module';
import {
  People_Routes,
  Profile_Routes,
  Rep_Responsibility_Routes,
} from 'app/modules/people/people-routing.module';
import { Reporting_Routes } from 'app/modules/reporting/reporting-routing.module';
import { System_Planning_Routes } from 'app/modules/system-planning/system-planning-routing.module';
import { unauthorizedRoutes } from 'app/modules/unauthorized/unauthorized-routing.module';
import {
  Adjustments_Routes,
  Inventory_Routes,
  JournalEntries_Routes,
  Projects_Routes,
  Revenue_Recognition_Routes,
} from './modules/accounting/accounting-routing.module';
import {
  Accounts_Routes,
  Administration_Routes,
  Ambient_Capacity_Routes,
  Closing_Dashboard_Routes,
  CycleConfiguration_Routes,
  Historical_Gas_Quality_Routes,
  Notices_Routes,
  Postings_Routes,
  Scheduling_Constrained_Routes,
} from './modules/administration/administration-routing.module';
import { Agent_Routes } from './modules/agents/agents-routing.module';
import { Auctions_Routes } from './modules/auctions/auctions-routing.module';
import { CapacityReleaseRoutes } from './modules/capacity-release/capacity-release-routing.module';
import {
  Contracts_List_Routes,
  Contract_Routes,
  GrandfatheredSupply_Routes,
} from './modules/contracts/contracts-routing-module';
import { Entity_Management_Routes } from './modules/entities-management/entities-management-routing.module';
import {
  Flowing_Gas_Routes,
  Imbalance_Routes,
  Storage_Routes,
} from './modules/flowing-gas/flowing-gas-routing.module';
import { Invoicing_Routes } from './modules/invoicing/invoicing-routing.module';
import { Job_Management_Routes } from './modules/job-management/job-management-routing.module';
import { Merger_Assignments_Routes } from './modules/merger-assignments/merger-assignments-routing.module';
import { Nominations_Routes } from './modules/nominations/nominations-routing.module';
import {
  Contractual_Rights_Routes,
  Lines_Routes,
  Locations_Routes,
  Location_Groups_Routes,
  Pipeline_Model_Routes,
  Scheduling_Order_Set_Routes,
  Sequences_Routes,
} from './modules/pipeline-model/pipeline-model-routing.module';
import {
  ChargeTypes_Routes,
  IndexRate_Routes,
  NonTariff_Routes,
  TariffRates_Routes,
} from './modules/rates/rates-routing.module';
import {
  Amendments_Routes,
  ContractConsolidation_Routes,
  Dealsheets_Routes,
  Service_Requests_Routes,
} from './modules/service-request/service-request-routing.module';
import { Sso_Routes } from './modules/sso/sso-routing.module';
import { Trades_Routes } from './modules/trades/trades-routing.module';
import { EPermissionOption, EUserType } from './store/auth/model/models';
import { AuthGuard } from './core/routeGuards/auth.guard';
import { ResourceGuard } from './core/routeGuards/resource.guard';
import { BISA_Butane_Routes, BISA_Stratton_Routes, Bisa_Home_Routes, Bisa_Manual_Postings, Bisa_Postings_Routes, Historical_Gas_Routes, Historical_Truck_Routes, Bisa_ButaneTruckSummary_Upload_Routes } from './modules/bisa/bisa-routing.module';
import { featureFlagGuard, bisaFeatureFlagGuard } from './core/routeGuards/feature-flag.guard';

const routes: Routes = [
  {
    path: 'account-locked',
    children: AccountLocked_Routes,
  },
  {
    path: 'service-requests',
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/service-request',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
    children: Service_Requests_Routes,
  },
  {
    path: 'grandfathered-supply',
    canActivate: [AuthGuard],
    children: GrandfatheredSupply_Routes,
  },
  {
    path: 'amendments',
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/amendments',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
    children: Amendments_Routes,
  },
  {
    path: 'auctions',
    canActivate: [AuthGuard],
    children: Auctions_Routes,
  },
  {
    path: 'tradeblotter',
    canActivate: [AuthGuard],
    children: Trades_Routes,
  },
  // TODO Uncomment to restore ITOnline route
  /*
  {
    path: 'it-online',
    canActivate: [AuthGuard],
    children: IT_Online_Routes,
  },
  */
  {
    path: 'deal-sheet',
    canActivate: [AuthGuard],
    children: Dealsheets_Routes,
  },
  {
    path: 'nominations',
    canActivate: [AuthGuard],
    children: Nominations_Routes,
  },
  {
    path: 'invoices',
    canActivate: [AuthGuard],
    children: Invoicing_Routes,
  },
  {
    path: 'capacity-release',
    canActivate: [AuthGuard],
    children: CapacityReleaseRoutes,
  },
  {
    path: 'contracts',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/modules/service-request/pages/contracts/contracts.module').then(
            m => m.ContractsModule
          ),
        canActivate: [ResourceGuard],
        data: {
          aclResource: '/service-request',
          aclAccessLevel: EPermissionOption.ReadOnly,
        },
      },
    ],
  },
  {
    path: 'contracts-list',
    canActivate: [AuthGuard],
    children: Contracts_List_Routes,
  },
  {
    path: 'contract',
    canActivate: [AuthGuard],
    children: Contract_Routes,
  },
  {
    path: 'contract-consolidation',
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/contract-consolidation',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
    children: ContractConsolidation_Routes,
  },
  {
    path: 'system-planning',
    canActivate: [AuthGuard],
    data: {
      aclResource: '/system-planning',
      aclAccessLevel: EPermissionOption.ReadOnly,
    },
    children: System_Planning_Routes,
  },
  {
    path: 'password-update',
    children: PasswordUpdate_Routes,
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    children: People_Routes,
  },
  {
    path: 'entities',
    canActivate: [AuthGuard],
    children: Entity_Management_Routes,
  },
  {
    path: 'agents',
    canActivate: [AuthGuard],
    children: Agent_Routes,
  },
  {
    path: 'merger-assignments',
    // TODO: uncomment when resource security is implemented for this path
    // canActivate: [AuthGuard, ResourceGuard],
    children: Merger_Assignments_Routes,
  },
  {
    path: 'job-management',
    canActivate: [AuthGuard],
    children: Job_Management_Routes,
  },
  {
    path: 'reporting',
    children: Reporting_Routes,
  },
  {
    path: 'rep-responsibility',
    canActivate: [AuthGuard],
    children: Rep_Responsibility_Routes,
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard],
    children: Rep_Responsibility_Routes,
  },
  {
    path: 'login',
    children: Login_Routes,
  },
  {
    path: 'locations',
    canActivate: [AuthGuard],
    children: Locations_Routes,
  },
  {
    path: 'location-groups',
    canActivate: [AuthGuard],
    children: Location_Groups_Routes,
  },
  {
    path: 'sequences',
    canActivate: [AuthGuard],
    children: Sequences_Routes,
  },
  {
    path: 'pipeline-model',
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/pipeline-model',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/pipeline-model/pipeline-model.module').then(
        m => m.PipelineModelModule
      ),
  },
  {
    path: 'pipeline-history',
    canActivate: [AuthGuard, ResourceGuard],
    data: {
      aclResource: '/pipeline-history',
      aclAccessLevel: EPermissionOption.ReadOnly,
      aclUserType: EUserType.Internal,
    },
    loadChildren: () =>
      import('app/modules/pipeline-model/pages/pipeline-history/pipeline-history.module').then(
        m => m.PipelineHistoryModule
      ),
  },
  {
    path: 'profile',
    children: Profile_Routes,
  },
  {
    path: 'password-reset',
    children: PasswordReset_Routes,
  },
  {
    path: 'home',
    children: Home_Routes,
    canActivate: [AuthGuard],
  },
  {
    path: 'lines',
    canActivate: [AuthGuard],
    children: Lines_Routes,
  },
  {
    path: 'index-rate',
    canActivate: [AuthGuard],
    children: IndexRate_Routes,
  },
  {
    path: 'charge-types',
    canActivate: [AuthGuard],
    children: ChargeTypes_Routes,
  },
  {
    path: 'tariff-rates',
    canActivate: [AuthGuard],
    children: TariffRates_Routes,
  },
  {
    path: 'flowing-gas',
    canActivate: [AuthGuard],
    children: Flowing_Gas_Routes,
  },
  {
    path: 'imbalance',
    canActivate: [AuthGuard],
    children: Imbalance_Routes,
  },
  {
    path: 'storage',
    canActivate: [AuthGuard],
    children: Storage_Routes,
  },
  {
    path: 'contractual-rates',
    canActivate: [AuthGuard],
    children: NonTariff_Routes,
  },
  {
    path: 'chart-of-accounts',
    canActivate: [AuthGuard],
    children: Administration_Routes,
  },
  {
    path: 'ambient-unsubscribed-capacity',
    canActivate: [AuthGuard],
    children: Ambient_Capacity_Routes,
  },
  {
    path: 'coastalbendheader/posting-gas-composition-historical',
    canActivate: [AuthGuard],
    children: Historical_Gas_Quality_Routes,
  },
  {
    path: 'informational-postings/scheduling-and-constrained-areas',
    canActivate: [AuthGuard],
    children: Scheduling_Constrained_Routes,
  },
  {
    path: 'cycle-configuration',
    canActivate: [AuthGuard],
    children: CycleConfiguration_Routes,
  },
  {
    path: 'journal-entries',
    canActivate: [AuthGuard],
    children: JournalEntries_Routes,
  },
  {
    path: 'adjustments',
    canActivate: [AuthGuard],
    children: Adjustments_Routes,
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    children: Projects_Routes,
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    children: Inventory_Routes,
  },
  {
    path: 'accounts',
    canActivate: [AuthGuard],
    children: Accounts_Routes,
  },
  {
    path: 'market-sector-assignments',
    canActivate: [AuthGuard],
    children: Pipeline_Model_Routes,
  },
  {
    path: 'scheduling-order-set',
    canActivate: [AuthGuard],
    children: Scheduling_Order_Set_Routes,
  },
  {
    path: 'contractual-rights',
    canActivate: [AuthGuard],
    children: Contractual_Rights_Routes,
  },
  {
    path: 'revenue-recognition',
    canActivate: [AuthGuard],
    children: Revenue_Recognition_Routes,
  },
  {
    path: 'closing-dashboard',
    canActivate: [AuthGuard],
    children: Closing_Dashboard_Routes,
  },
  {
    path: 'informational-postings/posting',
    canActivate: [AuthGuard],
    children: Postings_Routes,
  },
  {
    path: 'bisa/login',
    canActivate: [bisaFeatureFlagGuard],
    children: Login_Routes,
  },
  {
    path: 'bisa/mixed-butane-page',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: BISA_Butane_Routes,
  },
  {
    path: 'bisa/gas-composition-page',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: BISA_Stratton_Routes,
  },
  {
    path: 'bisa/gas-compostion-hisorical-page',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Historical_Gas_Routes,
  },
  {
    path: 'bisa/truck-hisorical-page',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Historical_Truck_Routes,
  },
  {
    path: 'bisa/posting',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Bisa_Postings_Routes,
  },
  {
    path: 'bisa/manual-posting',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Bisa_Manual_Postings,
  },
  {
    path: 'bisa/home',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Bisa_Home_Routes,
    data: {
      type: 'BISA'
    }
  },
  {
    path: 'bisa/butane-truck-summary-upload',
    canActivate: [AuthGuard, bisaFeatureFlagGuard],
    children: Bisa_ButaneTruckSummary_Upload_Routes,
  },
  {
    path: 'informational-postings/notice',
    canActivate: [AuthGuard],
    children: Notices_Routes,
  },
  {
    path: 'unauthorized',
    children: unauthorizedRoutes,
  },
  {
    path: 'sso',
    children: Sso_Routes,
  },
  {
    path: 'main',
    loadChildren: () => import('app/modules/infopost/pages/infopost-main/infopost-main.module').then(x => x.InfoPostMainModule),
    canActivate: [featureFlagGuard],
  },
  { path: '', redirectTo: '/main', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  //providers: [CurrentGasDayResolver],
})
export class AppRoutingModule { }
