import { Routes } from '@angular/router';
import { TSPEnum } from 'app/components/app-common-views/infopost-nav-container/sidebar-nav/nav-menus.constants';
import {
  InformationalWebLabels,
  MenuItems,
} from 'app/modules/infopost/informational-posting-web.utils';
import { BISAACLResource } from './bisa-constants';

export const BISA_Butane_Routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-butane-stratton/bisa-butane-stratton.module').then(
        (m) => m.BisaButaneStrattonModule
      ),
    data: {
      type: 'mixedButane',
      aclResource: BISAACLResource.BLMMixedButane
    },
  },
];

export const BISA_Stratton_Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-butane-stratton/bisa-butane-stratton.module').then(
        (m) => m.BisaButaneStrattonModule
      ),
    data: {
      type: 'gasComposition',
      aclResource: BISAACLResource.gasCompositionPage,
    },
  },
];

export const Historical_Gas_Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-historical/bisa-historical.module').then(
        (m) => m.BisaHistoricalModule
      ),
    data: {
      type: 'historicalGasComposition',
      aclResource: BISAACLResource.historyPage,
    },
  },
];

export const Historical_Truck_Routes = [
  {
    path: '',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-historical/bisa-historical.module').then(
        (m) => m.BisaHistoricalModule
      ),
    data: {
      type: 'historicalTruckSummary',
      aclResource: BISAACLResource.truckHistoryPage,
    },
  },
];

export const Bisa_Postings_Routes = [
  {
    path: 'truck-unloading-summary',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-postings/bisa-postings.module').then(
        (m) => m.BisaPostingsModule
      ),
    data: {
      aclResource: BISAACLResource.truckUnloadingSummary,
      infoPostID: MenuItems.TRUCKUNLOADINGSUMMARY,
      title: InformationalWebLabels.TRUCKUNLOADINGSUMMARYLABEL,
    },
  },
  {
    path: 'butane-injection-daily-report',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-postings/bisa-postings.module').then(
        (m) => m.BisaPostingsModule
      ),
    data: {
      aclResource: BISAACLResource.butaneDailyInjectionReport,
      infoPostID: MenuItems.BUTANEINJECTIONDAILYREPORT,
      title: InformationalWebLabels.BUTANEINJECTIONDAILYREPORTLABEL,
    },
  },
  {
    path: 'butane-injection-monthly-report',
    loadChildren: () =>
      import('app/modules/bisa/pages/bisa-postings/bisa-postings.module').then(
        (m) => m.BisaPostingsModule
      ),
    data: {
      aclResource: BISAACLResource.butaneMonthInjectionReport,
      infoPostID: MenuItems.BUTANEINJECTIONMONTHLYREPORT,
      title: InformationalWebLabels.BUTANEINJECTIONMONTHLYREPORTLABEL,
    },
  },
];

export const Bisa_Manual_Postings = [
  {
    path: '',
    loadChildren: () =>
      import('../administration/pages/posting/view-posting/view-posting.module').then(
        (m) => m.ViewPostingModule
      ),
    data: {
      aclResource: BISAACLResource.posting,
      type: 'BISA',
    },
  },
  {
    path: 'new',
    loadChildren: () =>
      import('../administration/pages/posting/create-posting/create-posting.module').then(
        (m) => m.CreatePostingModule
      ),
    data: {
      aclResource: BISAACLResource.posting,
      type: 'BISA',
    },
  },
  {
    path: ':id',
    loadChildren: () =>
      import('../administration/pages/posting/posting-details/posting-details.module').then(
        (m) => m.PostingDetailsModule
      ),
    data: {
      aclResource: BISAACLResource.posting,
      type: 'BISA',
    },
  },
  {
    path: ':id/edit',
    loadChildren: () =>
      import('../administration/pages/posting/create-posting/create-posting.module').then(
        (m) => m.CreatePostingModule
      ),
    data: {
      aclResource: BISAACLResource.posting,
      type: 'BISA'
    },
  },
];

export const Bisa_Home_Routes = [
  {
    path: '',
    loadComponent: () =>
      import('app/modules/bisa/pages/bisa-home/bisa-home.component').then(
        (m) => m.BisaHomeComponent
      )
  },
];

export const Bisa_ButaneTruckSummary_Upload_Routes = [
  {
    path: '',
    loadComponent: () => import('app/modules/bisa/pages/butane-truck-summary-upload/butane-truck-summary-upload.component').then(
      m => m.ButaneTruckSummaryUploadComponent
    ),
    data: {
      aclResource: BISAACLResource.truckUploadingSummary,
      type: 'upload'
    }
  },
]
