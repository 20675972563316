import { Action } from '@ngrx/store';
import { NoticeParamsModel } from 'app/store/notices/notice-response-model';
import { Notice, NoticeDetails, NoticePayload, Noticeslist } from '@gms/reporting-api';

export enum ENoticeActions {
  FETCH_NOTICES_LIST = '[NOTICES] Fetch Notices list',
  FETCH_NOTICES_LIST_SUCCESS = '[NOTICES] Fetch Notices list Success',
  FETCH_NOTICES_LIST_ERROR = '[NOTICES] Fetch Notices list Failure',

  NOTICES_FILTER_LIST = '[NOTICES] Post Notices Filter Data',
  FETCH_NOTICES_FILTER_LIST = '[NOTICES] Fetch Notices Filter Data',
  UPDATE_NOTICES_FILTER_FLAG = '[NOTICES] Update Notices Filter Data Flag',
  
  FETCH_NOTICE_LOOKUP = '[NOTICES] Fetch Notice Lookup Data',
  FETCH_NOTICE_LOOKUP_SUCCESS = '[NOTICES] Fetch Notice Lookup Data success',
  FETCH_NOTICE_LOOKUP_FAILURE = '[NOTICES] Fetch Notice Lookup Data failure',
  DELETE_NOTICE = "DELETE_NOTICE",
  DELETE_NOTICE_SUCCESS = "DELETE_NOTICE_SUCCESS",
  DELETE_NOTICE_FAILURE = "DELETE_NOTICE_FAILURE",
  
  CREATE_NEW_NOTICE = '[NOTICES] Create New Notice',
  CREATE_NEW_NOTICE_SUCCESS = '[NOTICES] Create New Notice Success',
  CREATE_NEW_NOTICE_FAILURE = '[NOTICES] Create New Notice Failure',

  FETCH_NOTICE_BY_ID = '[NOTICES] Fetch Notice Data By ID',
  FETCH_NOTICE_BY_ID_SUCCESS = '[NOTICES] Fetch Notice Data By ID Success',
  FETCH_NOTICE_BY_ID_FAILURE = '[NOTICES] Fetch Notice Data By ID Failure',
  RESET_NOTICE_BY_ID_SUCCESS = '[NOTICES] Reset Notice Data By ID Success',


  DOWNLOAD_NOTICE_DOCUMENT = '[NOTICES] Download Notice Document',
  DOWNLOAD_NOTICE_DOCUMENT_SUCCESS = '[NOTICES] Download Notice Document success',
  DOWNLOAD_NOTICE_DOCUMENT_FAILURE = '[NOTICES] Download Notice Document failure',
}


export class LoadNoticeListRequestAction implements Action {
  public readonly type = ENoticeActions.FETCH_NOTICES_LIST;

  constructor(public payload: NoticeParamsModel) { }
}

export class LoadNoticeListSuccessAction implements Action {
  public readonly type = ENoticeActions.FETCH_NOTICES_LIST_SUCCESS;

  constructor(public payload: NoticeDetails) { }
}
export class LoadNoticeListFailureAction implements Action {
  public readonly type = ENoticeActions.FETCH_NOTICES_LIST_ERROR;

  constructor(public payload: { error: Error }) { }
}


export class FetchNoticeFilterData implements Action {
  public readonly type = ENoticeActions.FETCH_NOTICES_FILTER_LIST;

  constructor() { }
}

export class PostFilterData implements Action {
  public readonly type = ENoticeActions.NOTICES_FILTER_LIST;

  constructor(public payload: NoticeParamsModel) { }
}

export class UpdatePostFilterDataFlag implements Action {
  public readonly type = ENoticeActions.UPDATE_NOTICES_FILTER_FLAG;

  constructor(public istriggerSearchApi: boolean) { }
}


export class FetchNoticeLookupAction implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_LOOKUP;
  
    constructor(public mode: string) { }
  }
  export class FetchNoticeLookupSuccess implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_LOOKUP_SUCCESS;
  
    constructor(public payload: Notice[]) { }
  }
  export class FetchNoticeLookupFailure implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_LOOKUP_FAILURE;
  
    constructor(public payload: { error: Error }) { }
  }
  export class DeleteNoticeAction implements Action {
    public readonly type = ENoticeActions.DELETE_NOTICE;
  
    constructor(public noticeId: number) { }
  }
  export class DeleteNoticeActionSuccess implements Action {
    public readonly type = ENoticeActions.DELETE_NOTICE_SUCCESS;
  
    constructor(public payload: boolean) { }
  }
  export class DeleteNoticeActionFailure implements Action {
    public readonly type = ENoticeActions.DELETE_NOTICE_FAILURE;
  
    constructor(public payload: { error: Error }) { }
  }
  export class CreateNoticeAction implements Action {
    public readonly type = ENoticeActions.CREATE_NEW_NOTICE;
  
    constructor(public payload: NoticePayload) { }
  }
  export class CreateNoticeSuccessAction implements Action {
    public readonly type = ENoticeActions.CREATE_NEW_NOTICE_SUCCESS;
  
    constructor(public payload: Noticeslist) { }
  }
  export class CreateNoticeFailureAction implements Action {
    public readonly type = ENoticeActions.CREATE_NEW_NOTICE_FAILURE;
  
    constructor(public payload: { error: Error }) { }
  }
  export class GetNoticeDetailsById implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_BY_ID;
  
    constructor(public noticeId:number ) { }
  }
  
  export class GetNoticeDetailsByIdSuccess implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_BY_ID_SUCCESS;
  
    constructor(public payload: Noticeslist) { }
  }
  
  export class GetNoticeDetailsByIdFailure implements Action {
    public readonly type = ENoticeActions.FETCH_NOTICE_BY_ID_FAILURE;
  
    constructor(public payload: { error: Error }) { }
  }

  export class DownloadNoticeDocumentAction implements Action {
    public readonly type = ENoticeActions.DOWNLOAD_NOTICE_DOCUMENT;
  
    constructor(public noticeTrackedID: number) { }
  }
  
  export class DownloadNoticeDocumentActionSuccess implements Action {
    public readonly type = ENoticeActions.DOWNLOAD_NOTICE_DOCUMENT_SUCCESS;
  
    constructor() { }
  }
  
  export class DownloadNoticeDocumentActionFailure implements Action {
    public readonly type = ENoticeActions.DOWNLOAD_NOTICE_DOCUMENT_FAILURE;
  
    constructor(public payload: { error: Error }) { }
  }
  export class ResetGetNoticeDetailsByIdSuccess implements Action {
    public readonly type = ENoticeActions.RESET_NOTICE_BY_ID_SUCCESS;
  
    constructor() { }
  }
  

export type NoticeActions =
  | LoadNoticeListRequestAction
  | LoadNoticeListSuccessAction
  | LoadNoticeListFailureAction  
  | FetchNoticeFilterData
  | PostFilterData
  | UpdatePostFilterDataFlag
  | FetchNoticeLookupAction
  | FetchNoticeLookupSuccess
  | FetchNoticeLookupFailure
  | DeleteNoticeAction
  | DeleteNoticeActionSuccess
  | DeleteNoticeActionFailure
  | CreateNoticeAction
  | CreateNoticeSuccessAction
  | CreateNoticeFailureAction
  | GetNoticeDetailsById
  | GetNoticeDetailsByIdSuccess
  | GetNoticeDetailsByIdFailure
  | ResetGetNoticeDetailsByIdSuccess;
