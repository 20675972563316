import { clearState, createErrorState, createLoadingState, createSuccessState } from "../app/app.models";
import { BisaActions, EBisaActions } from "./bisa.actions";
import { IBisaState, initialBisaState } from "./bisa.state";

export function bisaReducers(
    state = initialBisaState,
    action: BisaActions
): IBisaState {
    switch (action.type) {
        // case EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA: 
        //     return {
        //         ...state,
        //         bisaMixedButaneData: clearState(state.bisaMixedButaneData),
        //     }
        case EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA:
            return {
                ...state,
                bisaMixedButaneData: createLoadingState(state.bisaMixedButaneData),
            }
        case EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_SUCCESS:
            return {
                ...state,
                bisaMixedButaneData: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_ERROR:
            return {
                ...state,
                bisaMixedButaneData: createErrorState(action.payload.error),
            }
        // case EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA: 
        //     return {
        //         ...state,
        //         strattonRidgeData: clearState(state.strattonRidgeData),
        //     }
        case EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA:
            return {
                ...state,
                strattonRidgeData: createLoadingState(state.bisaMixedButaneData),
            }
        case EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA_SUCCESS:
            return {
                ...state,
                strattonRidgeData: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA_ERROR:
            return {
                ...state,
                strattonRidgeData: createErrorState(action.payload.error),
            }
        case EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION:
            return {
                ...state,
                bisaHistoricalGasComposition: createLoadingState(state.bisaHistoricalGasComposition),
            }
        case EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION_SUCCESS:
            return {
                ...state,
                bisaHistoricalGasComposition: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION_ERROR:
            return {
                ...state,
                bisaHistoricalGasComposition: createErrorState(action.payload.error),
            }
        case EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY:
            return {
                ...state,
                bisaHistoricalTruckSummary: createLoadingState(state.bisaHistoricalTruckSummary),
            }
        case EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY_SUCCESS:
            return {
                ...state,
                bisaHistoricalTruckSummary: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY_ERROR:
            return {
                ...state,
                bisaHistoricalTruckSummary: createErrorState(action.payload.error),
            }
        case EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING:
            return {
                ...state,
                bisaPostingTruckUnloadingSummary: createLoadingState(state.bisaPostingTruckUnloadingSummary),
            }
        case EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING_SUCCESS:
            return {
                ...state,
                bisaPostingTruckUnloadingSummary: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING_ERROR:
            return {
                ...state,
                bisaPostingTruckUnloadingSummary: createErrorState(action.payload.error),
            }
        case EBisaActions.FETCH_POSTINGS_BI_DR:
            return {
                ...state,
                bisaPostingButaneInjectionDR: createLoadingState(state.bisaPostingButaneInjectionDR),
            }
        case EBisaActions.FETCH_POSTINGS_BI_DR_SUCCESS:
            return {
                ...state,
                bisaPostingButaneInjectionDR: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_POSTINGS_BI_DR_ERROR:
            return {
                ...state,
                bisaPostingButaneInjectionDR: createErrorState(action.payload.error),
            }
        case EBisaActions.FETCH_POSTINGS_BI_MR:
            return {
                ...state,
                bisaPostingButaneInjectionMR: createLoadingState(state.bisaPostingButaneInjectionMR),
            }
        case EBisaActions.FETCH_POSTINGS_BI_MR_SUCCESS:
            return {
                ...state,
                bisaPostingButaneInjectionMR: createSuccessState(action.payload.data),
            }
        case EBisaActions.FETCH_POSTINGS_BI_MR_ERROR:
            return {
                ...state,
                bisaPostingButaneInjectionMR: createErrorState(action.payload.error),
            }

        case EBisaActions.DELETE_HISTORICAL_GC_TS:
            return {
                ...state,
                bisaDeleteHistoricalGCTS: createLoadingState(state.bisaDeleteHistoricalGCTS),
            }
        case EBisaActions.DELETE_HISTORICAL_GC_TS_SUCCESS:
            return {
                ...state,
                bisaDeleteHistoricalGCTS: createSuccessState(action.data),
            }
        case EBisaActions.DELETE_HISTORICAL_GC_TS_FAILURE:
            return {
                ...state,
                bisaDeleteHistoricalGCTS: createErrorState(action.payload.error),
            }
        case EBisaActions.UPLOAD_XML_FILE:
            return {
                ...state,
                bisaUploadXMLFile: createLoadingState(state.bisaUploadXMLFile),
            }
        case EBisaActions.UPLOAD_XML_FILE_SUCCESS:
            return {
                ...state,
                bisaUploadXMLFile: createSuccessState(action.payload),
            }
        case EBisaActions.RESET_UPLOAD_XML_FILE:
            return {
                ...state,
                bisaUploadXMLFile: createSuccessState(null),
            };
        case EBisaActions.UPLOAD_XML_FILE_ERROR:
            return {
                ...state,
                bisaUploadXMLFile: createErrorState(action.payload.error),
            }

        default:
            return state;
    }
}