import { HttpResponse } from "@angular/common/http";
import { MixedButaneSpecifications, StrattonRidgeHeaderGasComposition,BISAHistoricalPresignedURL } from "@gms/measurement-api";
import { Action } from "@ngrx/store";

export enum EBisaActions {
    FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA = '[BISA] Fetch Bisa Mixed Butane Specification Data',
    FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_SUCCESS = '[BISA] Fetch Bisa Mixed Butane Specification Data Success',
    FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_ERROR = '[BISA] Fetch Bisa Mixed Butane Specification Data Error',

    FETCH_BISA_STRATTON_RIDGE_DATA = '[BISA] Fetch Bisa Stratton Ridge Data',
    FETCH_BISA_STRATTON_RIDGE_DATA_SUCCESS = '[BISA] Fetch Bisa Stratton Ridge Data Success',
    FETCH_BISA_STRATTON_RIDGE_DATA_ERROR = '[BISA] Fetch Bisa Stratton Ridge Data Error',

    FETCH_HISTORICAL_GAS_COMPOSITION = '[BISA] Fetch Historical Gas Quality',
    FETCH_HISTORICAL_GAS_COMPOSITION_SUCCESS = '[BISA] Fetch Historical Gas Composition Success',
    FETCH_HISTORICAL_GAS_COMPOSITION_ERROR = '[BISA] Fetch Historical Gas Composition Error',

    FETCH_HISTORICAL_TRUCK_SUMMARY = '[BISA] Fetch Historical Truck Summary',
    FETCH_HISTORICAL_TRUCK_SUMMARY_SUCCESS = '[BISA] Fetch Historical Truck Summary Success',
    FETCH_HISTORICAL_TRUCK_SUMMARY_ERROR = '[BISA] Fetch Historical Truck Summary Error',

    FETCH_POSTINGS_TRUCK_UNLOADING = '[BISA] Fetch Postings Truck Unloading',
    FETCH_POSTINGS_TRUCK_UNLOADING_SUCCESS = '[BISA] Fetch Postings Truck Unloading Success',
    FETCH_POSTINGS_TRUCK_UNLOADING_ERROR = '[BISA] Fetch Postings Truck Unloading Error',

    FETCH_POSTINGS_BI_DR = '[BISA] Fetch Postings Butane Injection DR',
    FETCH_POSTINGS_BI_DR_SUCCESS = '[BISA] Fetch Postings Butane Injection DR Success',
    FETCH_POSTINGS_BI_DR_ERROR = '[BISA] Fetch Postings Butane Injection DR Error',

    FETCH_POSTINGS_BI_MR = '[BISA] Fetch Postings Butane Injection MR',
    FETCH_POSTINGS_BI_MR_SUCCESS = '[BISA] Fetch Postings Butane Injection MR Success',
    FETCH_POSTINGS_BI_MR_ERROR = '[BISA] Fetch Postings Butane Injection MR Error',

    UPLOAD_XML_FILE = '[BISA] Upload XML File',
    UPLOAD_XML_FILE_SUCCESS = '[BISA] Upload XML File Success',
    UPLOAD_XML_FILE_ERROR = '[BISA] Upload XML File Error',
    RESET_UPLOAD_XML_FILE = '[BISA] Reset Upload XML File',

    DELETE_HISTORICAL_GC_TS = '[BISA] Delete Historical Gas Composition',
    DELETE_HISTORICAL_GC_TS_SUCCESS = '[BISA] Delete Historical Gas Composition Success',
    DELETE_HISTORICAL_GC_TS_FAILURE = '[BISA] Delete Historical Gas Composition Failure',

}

export class FetchBISAMixedButaneSpecificationsData implements Action {
    public readonly type = EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA;
}

export class FetchBISAMixedButaneSpecificationsSuccess implements Action {
    public readonly type = EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_SUCCESS;
    constructor(public payload: { data: MixedButaneSpecifications }) { }
}

export class FetchBISAMixedButaneSpecificationsError implements Action {
    public readonly type = EBisaActions.FETCH_BISA_MIXED_BUTANE_SPECIFICATION_DATA_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchBISAStrattonRidgeData implements Action {
    public readonly type = EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA;
}

export class FetchBISAStrattonRidgeSuccess implements Action {
    public readonly type = EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA_SUCCESS;
    constructor(public payload: { data: StrattonRidgeHeaderGasComposition }) { }
}

export class FetchBISAStrattonRidgeError implements Action {
    public readonly type = EBisaActions.FETCH_BISA_STRATTON_RIDGE_DATA_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchHistoricalGasComposition implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION;
    constructor(public payload: IHistoricalDetails) { }
}

export class FetchHistoricalGasCompositionSuccess implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION_SUCCESS;
    constructor(public payload: IHistoricalSuccess) { }
}

export class FetchHistoricalGasCompositionError implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_GAS_COMPOSITION_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchHistoricalTruckSummary implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY;
    constructor(public payload: IHistoricalDetails) { }
}

export class FetchHistoricalTruckSummarySuccess implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY_SUCCESS;
    constructor(public payload: IHistoricalSuccess) { }
}

export class FetchHistoricalTruckSummaryError implements Action {

    public readonly type = EBisaActions.FETCH_HISTORICAL_TRUCK_SUMMARY_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchPostingsTruckUnloadingSummary implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING;
}

export class FetchPostingsTruckUnloadingSummarySuccess implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING_SUCCESS;
    constructor(public payload: { data: any }) { }
}

export class FetchPostingsTruckUnloadingSummaryError implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_TRUCK_UNLOADING_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchPostingsButaneInjectionDR implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_DR;
}

export class FetchPostingsButaneInjectionDRSuccess implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_DR_SUCCESS;
    constructor(public payload: { data: any }) { }
}

export class FetchPostingsButaneInjectionDRError implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_DR_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class FetchPostingsButaneInjectionMR implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_MR;
}

export class FetchPostingsButaneInjectionMRSuccess implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_MR_SUCCESS;
    constructor(public payload: { data: any }) { }
}

export class FetchPostingsButaneInjectionMRError implements Action {

    public readonly type = EBisaActions.FETCH_POSTINGS_BI_MR_ERROR;
    constructor(public payload: { error: Error }) { }
}

export class UploadXMLFile implements Action {
    public readonly type = EBisaActions.UPLOAD_XML_FILE;

    constructor(public payload: IXMLUploadPayload) { }
}
export class UploadXMLFileSuccess implements Action {
    public readonly type = EBisaActions.UPLOAD_XML_FILE_SUCCESS;

    constructor(public payload: IXMLUploadResponse) { }
}

export class UploadXMLFileFailure implements Action {
    public readonly type = EBisaActions.UPLOAD_XML_FILE_ERROR;

    constructor(public payload: { error: Error }) { }
}

export class ResetUploadXMLFileSuccess implements Action {
  public readonly type = EBisaActions.RESET_UPLOAD_XML_FILE;

  constructor() { }
}

export class DeleteHistoricalGCTS implements Action {
    public readonly type = EBisaActions.DELETE_HISTORICAL_GC_TS;

    constructor(public file_name: string, public reportType: string) { }
}
export class DeleteHistoricalGCTSSuccess implements Action {
    public readonly type = EBisaActions.DELETE_HISTORICAL_GC_TS_SUCCESS;

    constructor(public data:  HttpResponse<object>) { }
}

export class DeleteHistoricalGCTSFailure implements Action {
    public readonly type = EBisaActions.DELETE_HISTORICAL_GC_TS_FAILURE;

    constructor(public payload: { error: Error }) { }
}

export interface IHistoricalDetails {
    fromDate: Date,
    toDate: Date,
    reportType: string
}

export interface IHistoricalSuccess {
    //fileByteArray: string;
    //data: HttpResponse<Blob>;
    data: BISAHistoricalPresignedURL;
}

export interface IXMLUploadResponse {
    data: any;
}

export interface IXMLUploadPayload {
    file: File;
  }

export type BisaActions =
    | FetchBISAMixedButaneSpecificationsData
    | FetchBISAMixedButaneSpecificationsSuccess
    | FetchBISAMixedButaneSpecificationsError
    | FetchBISAStrattonRidgeData
    | FetchBISAStrattonRidgeSuccess
    | FetchBISAStrattonRidgeError
    | FetchHistoricalGasComposition
    | FetchHistoricalGasCompositionSuccess
    | FetchHistoricalGasCompositionError
    | FetchHistoricalTruckSummary
    | FetchHistoricalTruckSummarySuccess
    | FetchHistoricalTruckSummaryError
    | FetchPostingsTruckUnloadingSummary
    | FetchPostingsTruckUnloadingSummarySuccess
    | FetchPostingsTruckUnloadingSummaryError
    | FetchPostingsButaneInjectionDR
    | FetchPostingsButaneInjectionDRSuccess
    | FetchPostingsButaneInjectionDRError
    | FetchPostingsButaneInjectionMR
    | FetchPostingsButaneInjectionMRSuccess
    | FetchPostingsButaneInjectionMRError
    | UploadXMLFile
    | UploadXMLFileSuccess
    | UploadXMLFileFailure
    | ResetUploadXMLFileSuccess
    | DeleteHistoricalGCTS
    | DeleteHistoricalGCTSSuccess
    | DeleteHistoricalGCTSFailure;